import * as React from 'react'
import { useCallback } from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import { Box, Grid, GridItem, Text, useColorModeValue } from '@chakra-ui/react'
import DetailHeader from '../../molecules/shared/DetailHeader'
import WarrantiesTable from '../../organisms/warranties/WarrantiesTable'
import Card from '../../../../horizon/components/card/Card'
import GarageDetails from '../../organisms/garages/GarageDetails'
import { useGarage, useWarrantyTableData } from '../../../../hooks'
import Tabs from '../../molecules/shared/Tabs'
import NotesTable from '../../organisms/notes/NotesTable'
import { Note } from '../../../../types'

const GaragePage: React.FunctionComponent = () => {
  const { garage, onUpdate } = useGarage()
  const { warranties, loadingWarranties } = useWarrantyTableData({ garage })

  const handleNewNote = useCallback(
    (note: Note) => {
      onUpdate({ ...garage, notes: [note, ...garage.notes] })
    },
    [garage, onUpdate],
  )

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Garages', link: routes.garages.index },
        ...(garage ? [{ label: garage.name }] : []),
      ]}
      title={garage ? garage.name : 'Loading...'}
    >
      {garage ? (
        <Box>
          <Grid marginBottom="20px" templateColumns="repeat(3, 1fr)" gap="20px">
            <GridItem colSpan={2}>
              <DetailHeader
                title={garage.name}
                createdAt={garage.created_at}
                updatedAt={garage.updated_at}
              />
            </GridItem>
            <GridItem />
            <GridItem>
              <GarageDetails />
            </GridItem>
            <GridItem colSpan={3}>
              <Tabs
                tabs={[
                  {
                    title: 'Warranties',
                    slug: 'warranties',
                    onRender: () => (
                      <Card
                        padding="1rem"
                        display="flex"
                        flexDirection="column"
                        gap="1rem"
                      >
                        <Text
                          fontSize="xl"
                          color={textPrimary}
                          fontWeight="bold"
                          paddingX="0.5rem"
                        >
                          Warranties
                        </Text>
                        <WarrantiesTable
                          data={warranties}
                          loading={loadingWarranties}
                        />
                      </Card>
                    ),
                  },
                  {
                    title: 'Notes',
                    slug: 'notes',
                    onRender: () => (
                      <Card
                        padding="1rem"
                        display="flex"
                        flexDirection="column"
                        gap="1rem"
                      >
                        <Text
                          fontSize="xl"
                          color={textPrimary}
                          fontWeight="bold"
                          paddingX="0.5rem"
                        >
                          Notes
                        </Text>
                        <NotesTable
                          morph={garage.morph}
                          notes={garage.notes}
                          onCreated={handleNewNote}
                        />
                      </Card>
                    ),
                  },
                ]}
              />
            </GridItem>
          </Grid>
        </Box>
      ) : null}
    </AdminTemplate>
  )
}

export default GaragePage
