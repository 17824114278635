import React, { useCallback } from 'react'
import { Morph, Task } from '../../../../types'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import TaskDetails from './TaskDetails'

const TaskModal: React.FunctionComponent<{
  morph: Morph
  open: boolean
  task?: Task
  onSaved?: (task: Task) => void
  onClose: () => void
}> = ({ morph, task, open, onSaved, onClose }) => {
  const handleSaved = useCallback(
    (task: Task) => {
      if (onSaved) {
        onSaved(task)
      }
      onClose()
    },
    [onSaved, onClose],
  )

  return (
    <Modal closeOnOverlayClick={false} isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minWidth="calc(100vh - 4rem)"
        maxWidth="600px"
        paddingBottom="1rem"
      >
        <ModalHeader>{task ? 'Edit Task' : 'Add New Task'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TaskDetails morph={morph} task={task} onSaved={handleSaved} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TaskModal
