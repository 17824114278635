import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { createColumnHelper, RowSelectionState } from '@tanstack/react-table'
import DataTable from '../../molecules/shared/DataTable'
import type {
  PaginatedResponse,
  PaginationMeta,
  Warranty,
} from '../../../../types'
import { format } from 'date-fns'
import { accessorColumn, DATE_FORMAT } from '../../../../utils'
import Actions from '../../molecules/shared/Actions'
import { MdVisibility } from 'react-icons/md'
import { reverse } from 'named-urls'
import routes from '../../../../routes'
import { Checkbox, Text } from '@chakra-ui/react'
import { useTableSort } from '../../../../hooks'

type WarrantyRow = {
  id: number
  name: string
  policyNumber: string
  expiryDate: string
  type: string
  duration: string
  garageCode: string
  garageName: string
  status: React.ReactElement
  agent: string
  warranty: Warranty
}

const WarrantiesTable: React.FunctionComponent<{
  data: PaginatedResponse<Warranty, PaginationMeta>
  loading: boolean
  showGarage?: boolean
  actions?: React.ReactElement
  selectedWarranties?: Warranty[]
  onChangeSelection?: (warranties: Warranty[]) => void
}> = ({
  data,
  loading,
  showGarage = false,
  actions,
  selectedWarranties,
  onChangeSelection,
}) => {
  const { sorting, onSortingChange } = useTableSort()

  const rows = useMemo<WarrantyRow[]>(
    () =>
      (data?.data ?? []).map((warranty: Warranty) => ({
        id: warranty.id,
        name: warranty.full_name,
        policyNumber: warranty.policy_number,
        expiryDate: format(warranty.expires_on, DATE_FORMAT),
        type: warranty.type,
        duration: `${warranty.length} month${warranty.length === 1 ? '' : 's'}`,
        garageCode: warranty.garage?.code ?? '-',
        garageName: warranty.garage?.name ?? '-',
        status: (
          <Text as="span" color={warranty.status?.colour}>
            {warranty.status?.title ?? '-'}
          </Text>
        ),
        agent: warranty.user?.full_name ?? '-',
        warranty,
      })),
    [data],
  )

  const selectedRows = useMemo(() => {
    const selected: RowSelectionState = {}
    for (let warranty of selectedWarranties ?? []) {
      selected[warranty.id] = true
    }
    return selected as RowSelectionState
  }, [selectedWarranties])

  const onRowSelectionChange = useCallback(
    (selection: RowSelectionState) => {
      const selectedIDs = Object.keys(selection)
      onChangeSelection(
        data.data.filter(
          (warranty) =>
            selectedIDs.includes(`${warranty.id}`) &&
            selection[`${warranty.id}`],
        ),
      )
    },
    [onChangeSelection, data],
  )

  const columnHelper = createColumnHelper<WarrantyRow>()

  const selectionHeader = useCallback(
    ({ table }) => (
      <Checkbox
        colorScheme="brand"
        id="selectAll"
        isChecked={table.getIsAllRowsSelected()}
        isIndeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    [],
  )

  const selectionCell = useCallback(
    ({ row }) => (
      <Checkbox
        colorScheme="brand"
        id={row.id}
        isChecked={row.getIsSelected()}
        isDisabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    [],
  )

  const columns = [
    ...(selectedWarranties !== undefined
      ? [
          columnHelper.display({
            id: 'select',
            header: selectionHeader,
            cell: selectionCell,
          }),
        ]
      : []),
    accessorColumn<WarrantyRow, string>(columnHelper, 'name', 'Customer Name', {
      sorting: true,
    }),
    accessorColumn<WarrantyRow, string>(
      columnHelper,
      'policyNumber',
      'Policy Number',
      { sorting: true },
    ),
    accessorColumn<WarrantyRow, string>(
      columnHelper,
      'expiryDate',
      'Expiry Date',
      { sorting: true },
    ),
    accessorColumn<WarrantyRow, string>(columnHelper, 'type', 'Product Type', {
      sorting: true,
    }),
    accessorColumn<WarrantyRow, string>(columnHelper, 'duration', 'Duration', {
      sorting: true,
    }),
    ...(showGarage
      ? []
      : [
          accessorColumn<WarrantyRow, string>(
            columnHelper,
            'garageCode',
            'Garage Code',
            {
              sorting: true,
            },
          ),
          accessorColumn<WarrantyRow, string>(
            columnHelper,
            'garageName',
            'Garage Name',
            {
              sorting: true,
            },
          ),
        ]),
    accessorColumn<WarrantyRow, string>(columnHelper, 'status', 'Status', {
      sorting: true,
    }),
    accessorColumn<WarrantyRow, string>(columnHelper, 'agent', 'Agent', {
      sorting: true,
    }),
    columnHelper.display({
      id: 'actions',
      cell: useCallback(
        (cell) => (
          <Actions
            actions={[
              {
                title: 'View',
                icon: MdVisibility,
                route: reverse(routes.warranties.detail, {
                  warrantyId: cell.row.original.id,
                }),
              },
            ]}
          />
        ),
        [],
      ),
    }),
  ]

  return (
    <DataTable
      meta={data?.meta}
      rows={rows}
      columns={columns as any}
      loading={loading}
      actions={actions}
      getRowID={(row: WarrantyRow) => row.id}
      selectedRows={selectedRows}
      onRowSelectionChange={onRowSelectionChange}
      sorting={sorting}
      onSortingChange={onSortingChange}
    />
  )
}

export default WarrantiesTable
