import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'

import { createColumnHelper } from '@tanstack/react-table'
import DataTable from '../../molecules/shared/DataTable'
import { Morph, Task } from '../../../../types'
import {
  accessorColumn,
  DATE_FORMAT,
  DATETIME_FORMAT,
  MANAGEMENT,
  TIME_FORMAT,
  userCanAccess,
} from '../../../../utils'
import { MdAdd, MdCheck, MdDelete, MdEdit, MdUndo } from 'react-icons/md'
import TableButtons from '../../molecules/shared/TableButtons'
import { format } from 'date-fns'
import TaskModal from './TaskModal'
import Actions from '../../molecules/shared/Actions'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import ConfirmationModal from '../../molecules/shared/ConfirmationModal'
import {
  useCompleteTask,
  useConfirmDeleteTask,
  useConfirmRevertTask,
} from '../../../../hooks'

type TaskRow = {
  record: Task
  type: string
  description: string
  assignedTo: string
  dueDate: string
  status: string
}

const TasksTable: React.FunctionComponent<{
  morph: Morph
  tasks?: Task[]
  onUpdated?: (task: Task, deleted?: boolean) => void
}> = ({ morph, tasks, onUpdated }) => {
  const user = useSelector(getUser)
  const isAdmin = useMemo(() => userCanAccess(user, MANAGEMENT), [user])
  const [showForm, setShowForm] = useState(false)
  const [selected, setSelected] = useState<Task | undefined>(undefined)
  const { onComplete } = useCompleteTask(onUpdated)
  const { modalProps: revertProps, onShow: onShowRevert } =
    useConfirmRevertTask(onUpdated)
  const { modalProps: deleteProps, onShow: onShowDelete } =
    useConfirmDeleteTask(onUpdated)

  const rows = useMemo<TaskRow[]>(
    () =>
      (tasks ?? []).map((task: Task) => ({
        record: task,
        type: task.type.name,
        description: task.description ?? '',
        assignedTo: task.assigned_to?.full_name ?? '-',
        dueDate: task.due_at ? format(task.due_at, DATETIME_FORMAT) : '-',
        status: task.completed_at
          ? [
              'Completed',
              ...(task.completed_by
                ? [`by ${task.completed_by.full_name}`]
                : []),
              'on',
              format(task.completed_at, DATE_FORMAT),
              'at',
              format(task.completed_at, TIME_FORMAT),
            ].join(' ')
          : '-',
      })),
    [tasks],
  )

  const columnHelper = createColumnHelper<TaskRow>()
  const columns = [
    accessorColumn<TaskRow, string>(columnHelper, 'type', 'Type'),
    accessorColumn<TaskRow, string>(columnHelper, 'description', 'Description'),
    accessorColumn<TaskRow, string>(columnHelper, 'assignedTo', 'Assigned to'),
    accessorColumn<TaskRow, string>(columnHelper, 'dueDate', 'Due Date'),
    accessorColumn<TaskRow, string>(columnHelper, 'status', 'Completed?'),
    columnHelper.display({
      id: 'actions',
      cell: useCallback(
        (cell) => (
          <Actions
            actions={[
              ...(cell.row.original.record.is_complete
                ? [
                    ...(isAdmin
                      ? [
                          {
                            title: 'Revert',
                            icon: MdUndo,
                            onClick: () =>
                              onShowRevert(cell.row.original.record),
                          },
                        ]
                      : []),
                  ]
                : [
                    {
                      title: 'Complete',
                      icon: MdCheck,
                      onClick: () => {
                        onComplete(cell.row.original.record)
                      },
                    },
                    {
                      title: 'Edit',
                      icon: MdEdit,
                      onClick: () => {
                        setSelected(cell.row.original.record)
                        setShowForm(true)
                      },
                    },
                    ...(isAdmin
                      ? [
                          {
                            title: 'Delete',
                            icon: MdDelete,
                            onClick: () =>
                              onShowDelete(cell.row.original.record),
                          },
                        ]
                      : []),
                  ]),
            ]}
          />
        ),
        [isAdmin, onComplete, onShowRevert, onShowDelete],
      ),
    }),
  ]

  return (
    <>
      <DataTable
        meta={null}
        rows={rows}
        columns={columns as any}
        loading={false}
        canSearch={false}
        actions={
          <TableButtons
            buttons={[
              {
                title: 'Add New Task',
                icon: MdAdd,
                onClick: () => {
                  setSelected(undefined)
                  setShowForm(true)
                },
              },
            ]}
          />
        }
      />
      <TaskModal
        morph={morph}
        task={selected}
        open={showForm}
        onSaved={onUpdated}
        onClose={() => setShowForm(false)}
      />
      <ConfirmationModal {...revertProps} />
      <ConfirmationModal {...deleteProps} />
    </>
  )
}

export default TasksTable
