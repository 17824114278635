import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom'
import routes from '../routes'
import { API } from '../services/API'
import Access from './access/Access'
import Admin from './admin/Admin'
import initialTheme from '../theme/theme'
import { ChakraProvider } from '@chakra-ui/react'
import { Store } from 'redux'
import { getAuthLoaded, getUser, setUser } from '../store/auth'
import { ServiceContext } from '../components/Services'
import { deserialiseTableParams } from '../utils'
import { updateQueryParams } from '../store/queryParams'

const Axios: React.FunctionComponent<PropsWithChildren<any>> = ({
  children,
}) => {
  const store = useStore()

  useEffect(() => {
    API.initialise(store as Store)
  }, [store])
  return <>{children}</>
}

const QueryParams: React.FunctionComponent<PropsWithChildren<any>> = ({
  children,
}) => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()

  const page = useMemo(() => searchParams.get('page'), [searchParams])
  const search = useMemo(() => searchParams.get('search'), [searchParams])
  const sort = useMemo(() => searchParams.get('sort'), [searchParams])
  const garage = useMemo(() => searchParams.get('garage'), [searchParams])
  const tab = useMemo(() => searchParams.get('tab'), [searchParams])
  const user = useMemo(() => searchParams.get('user'), [searchParams])
  const changeRef = useRef('')

  useEffect(() => {
    const params = deserialiseTableParams({
      page: page ?? undefined,
      search: search ?? undefined,
      sort: sort ?? undefined,
      garage: garage ?? undefined,
      tab: tab ?? undefined,
      user: user ?? undefined,
    })

    const json = JSON.stringify(params)
    if (json !== changeRef.current) {
      changeRef.current = json
      dispatch(updateQueryParams(params))
    }
  }, [dispatch, page, search, sort, garage, tab, user])

  return <>{children}</>
}

const App: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const loaded = useSelector(getAuthLoaded)
  const currentTheme = useMemo(() => initialTheme, [])

  useEffect(() => {
    if (!loaded) {
      services.auth
        .me()
        .then((response) => {
          dispatch(setUser(response))
        })
        .catch(() => {
          dispatch(setUser(null))
        })
    }
  }, [services, dispatch, loaded])

  let router = null

  if (loaded) {
    if (user) {
      router = (
        <Router>
          <QueryParams>
            <Routes>
              <Route
                path="/auth/*"
                element={<Navigate to={`${routes.dashboard}`} replace />}
              />
              <Route path="/*" element={<Admin />} />
            </Routes>
          </QueryParams>
        </Router>
      )
    } else {
      router = (
        <Router>
          <QueryParams>
            <Routes>
              <Route path="/auth/*" element={<Access />} />
              <Route
                path="/*"
                element={<Navigate to={`${routes.login}`} replace />}
              />
            </Routes>
          </QueryParams>
        </Router>
      )
    }
  }
  return (
    <ChakraProvider theme={currentTheme}>
      <Axios>{router}</Axios>
    </ChakraProvider>
  )
}

export default App
