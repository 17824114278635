import { useDispatch, useSelector } from 'react-redux'
import { getWarranty, loadWarranty, setWarranty } from '../store/warranties'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  Garage,
  type PaginatedResponse,
  type PaginationMeta,
  Product,
  Warranty,
} from '../types'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../store'
import { AvailableServices, ServiceContext } from '../components/Services'
import { useQueryParams } from './queryParams'
import { useLoading } from './misc'
import { WarrantyKey } from '../services/LoadingService'

export type WarrantyTableDataQuery = {
  garage?: Garage
  product?: Product
}

export type WarrantyTableData = {
  warranties: PaginatedResponse<Warranty, PaginationMeta> | null
  loadWarranties: (query?: WarrantyTableDataQuery) => void
  loadingWarranties: boolean
}

export const useWarrantyTableData = (
  query: WarrantyTableDataQuery = {},
  ready: boolean = true,
): WarrantyTableData => {
  const services: AvailableServices = useContext(ServiceContext)
  const { params } = useQueryParams()
  const queryRef = useRef<string>('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<PaginatedResponse<
    Warranty,
    PaginationMeta
  > | null>(null)

  const loadData = useCallback(
    ({ garage, product }: WarrantyTableDataQuery = {}) => {
      if (ready && params !== null) {
        setLoading(true)
        services.warranty
          .list({
            ...params,
            ...(garage ? { garage: garage.id } : {}),
            ...(product ? { product: product.id } : {}),
          })
          .then((response: PaginatedResponse<Warranty, PaginationMeta>) => {
            setLoading(false)
            setData(response)
          })
          .catch(() => {
            setLoading(false)
            setData(null)
          })
      }
    },
    [ready, services, params],
  )

  useEffect(() => {
    const { page, length, search, sort, garage: paramsGarage, user } = params
    const { garage, product } = query
    const serialised = JSON.stringify({
      page,
      length,
      search,
      sort,
      user,
      garage: paramsGarage ?? garage?.id,
      product: product?.id,
    })
    if (serialised !== queryRef.current) {
      queryRef.current = serialised
      loadData(query)
    }
  }, [loadData, params, query])

  return {
    warranties: data,
    loadWarranties: loadData,
    loadingWarranties: loading,
  }
}

type WarrantyParams = {
  warrantyId?: string
}

export const useWarranty = () => {
  const { warrantyId } = useParams<WarrantyParams>()
  const { warranty, exception } = useSelector(getWarranty)
  const { loading, service } = useLoading(new WarrantyKey(warrantyId))
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (
      warrantyId &&
      warrantyId !== `${warranty?.id}` &&
      !service.isLoading(new WarrantyKey(warrantyId))
    ) {
      dispatch(loadWarranty(parseInt(warrantyId)))
    }
  }, [warranty, warrantyId, service, dispatch])

  const updateWarranty = useCallback(
    (warranty: Warranty | null) => {
      dispatch(setWarranty(warranty))
    },
    [dispatch],
  )

  return {
    warranty,
    loading,
    exception,
    onUpdate: updateWarranty,
  }
}
