import { QueryParams, URLQuery } from '../types'

export const serialiseTableParams = (params: QueryParams): URLQuery => {
  const { page, search, sort, garage, tab, user } = params
  return {
    ...(page ? { page: `${params.page}` } : {}),
    ...(search ? { search } : {}),
    ...(sort ? { sort } : {}),
    ...(garage !== undefined ? { garage: `${garage ?? 0}` } : {}),
    ...(tab !== undefined ? { tab } : {}),
    ...(user !== undefined ? { user: `${user ?? 0}` } : {}),
  }
}

export const deserialiseTableParams = (query: URLQuery): QueryParams => {
  const { page, search, sort, garage, tab, user } = query
  return {
    page: page ? parseInt(page) : undefined,
    search: search ?? undefined,
    sort: sort ?? undefined,
    garage: garage ? parseInt(garage) : undefined,
    tab,
    user: user ? parseInt(user) : undefined,
  }
}
