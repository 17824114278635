import { API } from './API'
import type {
  APIException,
  SingularResponse,
  Task,
  TaskResponse,
  UpdateTask,
  User,
} from '../types'
import { mapTask, mapUpdateTask } from '../mappers'

export class TaskService {
  public async create(data: UpdateTask): Promise<Task> {
    return (await API.getConnection())
      .post('tasks', mapUpdateTask(data))
      .then((response: SingularResponse<TaskResponse>) =>
        mapTask(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async update(id: number, data: UpdateTask): Promise<Task> {
    return (await API.getConnection())
      .put(`tasks/${id}`, mapUpdateTask(data))
      .then((response: SingularResponse<TaskResponse>) =>
        mapTask(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async complete(id: number, completedBy?: User): Promise<Task> {
    return (await API.getConnection())
      .patch(
        `tasks/${id}/complete`,
        completedBy ? { completed_by_id: completedBy.id } : undefined,
      )
      .then((response: SingularResponse<TaskResponse>) =>
        mapTask(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async revert(id: number): Promise<Task> {
    return (await API.getConnection())
      .patch(`tasks/${id}/revert`)
      .then((response: SingularResponse<TaskResponse>) =>
        mapTask(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async delete(id: number): Promise<void> {
    return (await API.getConnection())
      .delete(`tasks/${id}`)
      .then(() => {})
      .catch((error: APIException | null) => API.handleError(error))
  }
}
