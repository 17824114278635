import type { Warranty, WarrantyResponse, WarrantyUpdate, WarrantyUpdateData } from '../types'
import { format } from 'date-fns'
import { DATABASE_DATE_FORMAT, DATABASE_DATETIME_FORMAT, WARRANTY_STATUSES } from '../utils'
import { mapGarage } from './garages'
import { mapUser } from './auth'
import { mapNote } from './notes'
import { mapTask } from './tasks'
import { WarrantyAddressFields, WarrantyCustomerFields, WarrantyVehicleFields } from '../forms'

export const mapWarranty = (data: WarrantyResponse): Warranty => {
  const {
    status,
    user,
    garage,
    expires_on,
    purchased_on,
    rejected_at,
    notes,
    tasks,
    renewed_at,
    renewed,
    renewals,
    created_at,
    updated_at,
    morph_type,
    ...attrs
  } = data
  return {
    ...attrs,
    status: status ? WARRANTY_STATUSES.find(({ key }) => key === status) : null,
    user: user ? mapUser(user) : null,
    garage: garage ? mapGarage(garage) : null,
    full_name: [data.title, data.first_name, data.last_name]
      .filter((p) => !!p)
      .join(' '),
    expires_on: new Date(expires_on),
    purchased_on: new Date(purchased_on),
    rejected_at: rejected_at ? new Date(rejected_at) : null,
    notes: notes == null ? undefined : notes.map(mapNote),
    tasks: tasks == null ? undefined : tasks.map(mapTask),
    renewed_at: renewed_at ? new Date(renewed_at) : null,
    renewed: renewed ? mapWarranty(renewed) : null,
    renewals: renewals == null ? undefined : renewals.map(mapWarranty),
    created_at: new Date(created_at),
    updated_at: new Date(updated_at),
    morph: {
      id: data.id,
      type: morph_type
    }
  }
}

export const mapWarrantyUpdate = (data: WarrantyUpdate): WarrantyUpdateData => {
  const { status, expires_on, purchased_on, rejected_at, ...attrs } = data
  return {
    ...attrs,
    ...(status ? { status: status.key } : {}),
    ...(expires_on
      ? { expires_on: format(expires_on, DATABASE_DATE_FORMAT) }
      : {}),
    ...(purchased_on
      ? { purchased_on: format(purchased_on, DATABASE_DATE_FORMAT) }
      : {}),
    ...(rejected_at
      ? { rejected_at: format(rejected_at, DATABASE_DATETIME_FORMAT) }
      : {})
  }
}

export const mapWarrantyVehicleFields = (
  data: WarrantyVehicleFields
): WarrantyUpdate => {
  const { registration, mileage, price, ...attrs } = data
  return {
    ...attrs,
    vehicle_registration: registration,
    mileage: parseInt(mileage),
    price: price ? parseInt(price) : null
  }
}

export const mapWarrantyCustomerFields = (
  data: WarrantyCustomerFields
): WarrantyUpdate => {
  const { firstName, lastName, ...attrs } = data
  return {
    ...attrs,
    first_name: firstName,
    last_name: lastName
  }
}

export const mapWarrantyAddressFields = (
  data: WarrantyAddressFields
): WarrantyUpdate => {
  const { line1, line2, line3, line4, ...attrs } = data
  return {
    ...attrs,
    address_1: line1,
    address_2: line2,
    address_3: line3,
    address_4: line4
  }
}
