import {
  Button,
  Flex,
  Icon,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import * as React from 'react'
import { useCallback } from 'react'

import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import DebouncedSearch from '../../atoms/DebouncedSearch'
import type { PaginationMeta } from '../../../../types'
import { useQueryParams } from '../../../../hooks'

const DataTable: React.FunctionComponent<{
  actions?: React.ReactElement
  columns: ColumnDef<any>
  loading: boolean
  meta: PaginationMeta | null
  rows: any[]
  getRowID?: (row: any) => number | string
  selectedRows?: RowSelectionState
  onRowSelectionChange?: (selection: RowSelectionState) => void
  canSearch?: boolean
  sorting?: SortingState
  onSortingChange?: OnChangeFn<SortingState>
}> = ({
  meta,
  rows,
  columns,
  loading,
  actions,
  getRowID,
  selectedRows,
  onRowSelectionChange,
  canSearch = true,
  sorting,
  onSortingChange,
}) => {
  const {
    params: { search },
    updateParams,
  } = useQueryParams()

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  )

  const [globalFilter, setGlobalFilter] = React.useState('')

  const selectPage = useCallback(
    (page: number) => {
      updateParams({ page })
    },
    [updateParams],
  )

  const updateSearch = useCallback(
    (query: string) => {
      updateParams({ search: query })
    },
    [updateParams],
  )

  const changeSelection = useCallback(
    (updater) => {
      onRowSelectionChange(updater(selectedRows))
    },
    [selectedRows, onRowSelectionChange],
  )

  const table = useReactTable<any>({
    data: rows,
    columns: columns as any,
    state: {
      columnFilters,
      globalFilter,
      rowSelection: selectedRows,
      sorting,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getRowId: getRowID as any,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: changeSelection,
    manualSorting: true,
    onSortingChange,
  })

  const textColour = useColorModeValue('navy.700', 'white')
  const borderColour = useColorModeValue('gray.200', 'whiteAlpha.100')
  const brandColour = useColorModeValue('brand.500', 'brand.400')
  const spinnerColour = useColorModeValue('white', 'brand.400')
  const spinnerBackgroundColour = useColorModeValue('brand.400', 'brand.700')

  const paging =
    meta?.total > 0 ? (
      <Flex w="100%" justify="space-between" paddingX="20px" paddingY="10px">
        {meta ? (
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            marginBottom={{ sm: '24px', md: '0px' }}
          >
            Showing {meta.from} to {meta.to} of {meta.total} record
            {meta.total === 1 ? '' : 's'}
          </Text>
        ) : null}
        {(meta?.lastPage ?? 0) > 1 ? (
          <div className="flex items-center gap-2">
            <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
              <Button
                variant="no-effects"
                onClick={() =>
                  !!meta?.previousPage ? selectPage(meta.previousPage) : null
                }
                transition="all .5s ease"
                width="40px"
                height="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={borderColour}
                display="flex"
                _hover={{
                  bg: 'whiteAlpha.100',
                  opacity: '0.7',
                }}
              >
                <Icon as={MdChevronLeft} w="16px" h="16px" color={textColour} />
              </Button>
              {[...Array(meta?.lastPage ?? 0).keys()].map((index) => {
                const pageNumber = index + 1
                return (
                  <Button
                    variant="no-effects"
                    transition="all .5s ease"
                    onClick={() => selectPage(pageNumber)}
                    w="40px"
                    h="40px"
                    borderRadius="50%"
                    bg={
                      pageNumber === meta.currentPage
                        ? brandColour
                        : 'transparent'
                    }
                    border={
                      pageNumber === meta.currentPage
                        ? 'none'
                        : '1px solid lightgray'
                    }
                    _hover={
                      pageNumber === meta.currentPage
                        ? {
                            opacity: '0.7',
                          }
                        : {
                            bg: 'whiteAlpha.100',
                          }
                    }
                    key={pageNumber}
                  >
                    <Text
                      fontSize="sm"
                      color={
                        pageNumber === meta.currentPage ? '#fff' : textColour
                      }
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                )
              })}
              <Button
                variant="no-effects"
                onClick={() =>
                  !!meta?.nextPage ? selectPage(meta.nextPage) : null
                }
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="50%"
                bg="transparent"
                border="1px solid"
                borderColor={borderColour}
                display="flex"
                _hover={{
                  bg: 'whiteAlpha.100',
                  opacity: '0.7',
                }}
              >
                <Icon
                  as={MdChevronRight}
                  w="16px"
                  h="16px"
                  color={textColour}
                />
              </Button>
            </Stack>
          </div>
        ) : null}
      </Flex>
    ) : null

  return (
    <Flex direction="column" w="100%" position="relative">
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
        opacity={loading ? 0.5 : 1}
        transition="opacity 0.25s ease-in-out"
      >
        {paging}
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          w="100%"
          px="22px"
          mb="36px"
        >
          {canSearch ? (
            <DebouncedSearch
              value={search ?? ''}
              onChange={(value) => updateSearch(value)}
              searchBarProps={{
                className: 'p-2 font-lg shadow border border-block',
                placeholder: 'Search...',
              }}
            />
          ) : null}
          {actions}
        </Flex>
        <Flex direction="column" w="100%" overflowX="auto">
          <Table variant="simple" color="gray.500" mb="24px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        pe="10px"
                        borderColor={borderColour}
                        key={header.id}
                        colSpan={header.colSpan}
                      >
                        {header.isPlaceholder ? null : (
                          <Flex
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                            justify="space-between"
                            align="center"
                            fontSize={{ sm: '10px', lg: '12px' }}
                            color="gray.400"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </Flex>
                        )}
                      </Th>
                    )
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr px="20px" key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor={borderColour}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Flex>
        {paging}
      </Flex>
      <Flex
        alignItems="flex-start"
        bottom="0"
        justifyContent="flex-end"
        left="0"
        opacity={loading ? 1 : 0}
        padding="2rem"
        position="absolute"
        pointerEvents={loading ? 'all' : 'none'}
        right="0"
        top="0"
        transition="opacity 0.25s ease-in-out"
      >
        <Flex
          alignItems="center"
          backgroundColor={spinnerBackgroundColour}
          borderRadius="1rem"
          justifyContent="center"
          padding="1rem"
        >
          <Spinner color={spinnerColour} size="xl" />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default DataTable
