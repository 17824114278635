import { Task } from '../types'
import { useCallback, useContext, useState } from 'react'
import { AvailableServices, ServiceContext } from '../components/Services'
import { useToast } from '@chakra-ui/react'
import { type ConfirmationModalProps } from '../modules/admin/molecules/shared/ConfirmationModal'
import { useSelector } from 'react-redux'
import { getUser } from '../store/auth'
import { MANAGEMENT, userCanAccess } from '../utils'

export const useCompleteTask = (
  onUpdated?: (task: Task) => void,
): { onComplete: (task: Task) => void } => {
  const services: AvailableServices = useContext(ServiceContext)
  const toast = useToast()
  const user = useSelector(getUser)

  const onComplete = useCallback(
    (task: Task) => {
      services.task
        .complete(task.id, userCanAccess(user, MANAGEMENT) ? user : undefined)
        .then((completed) => {
          if (onUpdated) {
            onUpdated(completed)
          }
        })
        .catch(() => {
          toast({
            title: 'Task Completion Failed',
            description: `The selected ${task?.type?.name} task could not be completed`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    },
    [services, toast, user, onUpdated],
  )

  return {
    onComplete,
  }
}

export const useConfirmRevertTask = (
  onUpdated?: (task: Task) => void,
): { onShow: (task: Task) => void; modalProps: ConfirmationModalProps } => {
  const services: AvailableServices = useContext(ServiceContext)
  const toast = useToast()
  const [task, setTask] = useState<Task | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const onConfirm = useCallback(() => {
    setLoading(true)
    services.task
      .revert(task.id)
      .then((reverted) => {
        if (onUpdated) {
          onUpdated(reverted)
        }
        setLoading(false)
        toast({
          title: 'Task Reverted',
          description: `The selected ${task?.type?.name} task has been successfully reverted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setOpen(false)
      })
      .catch(() => {
        setLoading(false)
        toast({
          title: 'Task Revert Failed',
          description: `The selected ${task?.type?.name} task could not be un-completed`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }, [services, toast, task, onUpdated])

  return {
    onShow: (task: Task) => {
      setTask(task)
      setOpen(true)
    },
    modalProps: {
      open,
      title: 'Revert this task?',
      body: `Are you sure you want to un-complete this ${task?.type?.name} task?`,
      loading,
      onConfirm,
      onDeny: () => setOpen(false),
      onClose: () => setOpen(false),
    },
  }
}

export const useConfirmDeleteTask = (
  onUpdated?: (task: Task, deleted?: boolean) => void,
): { onShow: (task: Task) => void; modalProps: ConfirmationModalProps } => {
  const services: AvailableServices = useContext(ServiceContext)
  const toast = useToast()
  const [task, setTask] = useState<Task | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const onConfirm = useCallback(() => {
    setLoading(true)
    services.task
      .delete(task.id)
      .then(() => {
        if (onUpdated) {
          onUpdated(task, true)
        }
        setLoading(false)
        toast({
          title: 'Task Deleted',
          description: `The selected ${task?.type?.name} task has been successfully deleted`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setOpen(false)
      })
      .catch(() => {
        setLoading(false)
        toast({
          title: 'Task Deletion Failed',
          description: `The selected ${task?.type?.name} task could not be deleted`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }, [services, toast, task, onUpdated])

  return {
    onShow: (task: Task) => {
      setTask(task)
      setOpen(true)
    },
    modalProps: {
      open,
      title: 'Delete this task?',
      body: `Are you sure you want to delete this ${task?.type?.name} task?`,
      loading,
      onConfirm,
      onDeny: () => setOpen(false),
      onClose: () => setOpen(false),
    },
  }
}
